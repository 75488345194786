import combineReducers from 'react-combine-reducers';

import { ApplicationReducerType } from '@context/root-reducers/types';
import { initialOffsetState, offsetReducer } from '@context/offset/reducer';
import { formReducer, initialFormState } from '@context/form/reducer';
import { categoryContentsReducer, initialCategoryContentsState } from '@context/category-contents/reducer';
import { diagnosisReducer, initialDiagnosisState } from '@context/diagnosis/reducer';
import { drugInteractionReducer, initialDrugInteractionState } from '@context/drug-interaction/reducer';
import { favoritesReducer, initialFavoritesState } from '@context/favorites/reducer';
import { codeContentReducer, initialCodeContentState } from '@context/code-contents/reducer';
import { initialSearchCID10State, searchCID10Reducer } from '@context/search-cid10/reducer';
import { initialSearchCodesState, searchCodesReducer } from '@context/search-codes/reducer';
import { generalSearchReducer, initialGeneralSearchState } from '@context/general-search/reducer';
import { favoritesTabReducer, initialFavoritesTabState } from '@context/favorites-tab/reducer';
import { initialSignUpLoginState, signUpLoginReducer } from '@context/sign-up-login/reducer';
import { subHeaderPersistentReducer, initialSubHeaderPersistentState } from '@context/sub-header-persistent/reducer';
import { experimentsReducer, initialExperimentsState } from '@context/experiments/reducer';
import { initialRemoteConfigState, remoteConfigReducer } from '@context/remote-config/reducer';

export const [mainReducer, initialState] = combineReducers<ApplicationReducerType>({
  offsetState: [offsetReducer, initialOffsetState],
  formState: [formReducer, initialFormState],
  categoryContentsState: [categoryContentsReducer, initialCategoryContentsState],
  diagnosisState: [diagnosisReducer, initialDiagnosisState],
  drugInteractionState: [drugInteractionReducer, initialDrugInteractionState],
  favoritesState: [favoritesReducer, initialFavoritesState],
  codeContentsState: [codeContentReducer, initialCodeContentState],
  searchCID10: [searchCID10Reducer, initialSearchCID10State],
  searchCodes: [searchCodesReducer, initialSearchCodesState],
  generalSearch: [generalSearchReducer, initialGeneralSearchState],
  favoritesTab: [favoritesTabReducer, initialFavoritesTabState],
  signUpLogin: [signUpLoginReducer, initialSignUpLoginState],
  subHeaderPersistent: [subHeaderPersistentReducer, initialSubHeaderPersistentState],
  experiments: [experimentsReducer, initialExperimentsState],
  remoteConfig: [remoteConfigReducer, initialRemoteConfigState],
});
