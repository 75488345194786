import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { initSentry } from '@configs/sentry';
import { ContextProvider } from '@context/index';
import Layout from '@layout/Layout';
import { Provider } from '@pebmed/storybook-react';
import { ConsentPlugin, enableGdprContext } from '@snowplow/browser-plugin-consent';
import { enableFormTracking, FormTrackingPlugin } from '@snowplow/browser-plugin-form-tracking';
import { enableLinkClickTracking, LinkClickTrackingPlugin } from '@snowplow/browser-plugin-link-click-tracking';
import { PerformanceTimingPlugin } from '@snowplow/browser-plugin-performance-timing';
import { enableActivityTracking, newTracker } from '@snowplow/browser-tracker';
import { defaultTheme } from '@styles/theme';
import { Wootric } from '@configs/wootric';
import { useRouter } from 'next/router';
import { analyticsHelper } from '@analytics/helpers/analytics';
import { crossPlatform } from '@shared/utils/cross-platform';
import HeadTags from '@head-tags/page-tags/HeadTags';
import RedirectPages from '@components/redirect-pages';
import useAddCookieNoticeScript from '@hooks/use-add-cookie-notice-script';
import { useRemoteConfig } from '@hooks/use-remote-config';

initSentry();

function WhiteBookApp({ Component, pageProps }) {
  const analytics = analyticsHelper();
  let pagePropsAux = pageProps;
  const { asPath } = useRouter();
  const [loading, setLoading] = useState(true);
  const { initRemoteConfig } = useRemoteConfig();
  useAddCookieNoticeScript();

  if (Component.pageProps) {
    pagePropsAux = { ...Component.pageProps, ...pageProps };
  }

  useEffect(() => {
    newTracker('sp1', `${process.env.NEXT_PUBLIC_COLLECTOR}`, {
      appId: `${process.env.NEXT_PUBLIC_APP_ID}`,
      plugins: [PerformanceTimingPlugin(), FormTrackingPlugin(), LinkClickTrackingPlugin(), ConsentPlugin()],
      discoverRootDomain: true,
    });

    enableGdprContext({
      basisForProcessing: 'consent',
      documentId: 'https://pebmed.com.br/termos-de-uso/',
      documentVersion: '1.1.0',
      documentDescription: 'this document describes consent basis for processing',
    });

    enableActivityTracking({
      minimumVisitLength: 30,
      heartbeatDelay: 10,
    });

    const create = () => {
      if (process.browser) {
        if (localStorage.getItem('snowplow')) {
          return false;
        }

        localStorage.setItem(
          'snowplow',
          JSON.stringify({
            user: {},
            category: {},
            topic: {},
            experiment: {},
            prescription: {},
          }),
        );
      }
      return null;
    };

    create();

    enableFormTracking();
    enableLinkClickTracking();
    initRemoteConfig();
  }, []);

  useEffect(() => {
    hasOnlyCookie();

    if (!pageProps.keepCategoryEntity) {
      analytics.clear('category');
    }

    if (!pageProps.keepTopicEntity) {
      analytics.clear('topic');
    }

    if (pageProps.isPageManualSendPageView) return;

    analytics.sendPageView();
  }, [asPath]);

  useEffect(() => {
    return () => {
      setLoading(true);
    };
  }, [asPath]);

  const hasOnlyCookie = async () => {
    const isLoading = await crossPlatform();
    setLoading(isLoading);
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <HeadTags {...pagePropsAux} />
      <RedirectPages>
        <Provider customTheme={defaultTheme}>
          <ThemeProvider theme={defaultTheme}>
            <ContextProvider>
              {!loading && (
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                <Layout {...pagePropsAux}>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <Component {...pagePropsAux} />
                </Layout>
              )}
            </ContextProvider>
            <Wootric />
          </ThemeProvider>
        </Provider>
      </RedirectPages>
    </>
  );
}

export default WhiteBookApp;
