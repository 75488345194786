export type HttpMethod = 'get' | 'GET' | 'delete' | 'DELETE' | 'post' | 'POST' | 'put' | 'PUT' | 'patch' | 'PATCH';

export type HttpRequest = {
  url: string;
  method: HttpMethod;
  body?;
  isAuthenticated?: boolean;
  useCustomToken?: boolean;
  headers?;
};

export enum HttpStatusCode {
  Ok = 200,
  Created = 201,
  NoContent = 204,
  NotModified = 304,
  AlreadyReported = 208,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  Gone = 410,
  PreconditionFailed = 412,
  NonProcessableEntity = 422,
  ServerError = 500,
  PaymentRequired = 402,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HttpResponse<T = any> = {
  statusCode: HttpStatusCode;
  body?: T;
};

export interface HttpResponseError {
  codigo: string;
  mensagem: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface HttpClient<R = any> {
  request: (data: HttpRequest) => Promise<HttpResponse<R>>;
}
