import { IUserLocalStorage } from '@shared/utils/user';
import { KeysLocalStorageEnum } from '@local-storage/types';
import LocalStorage from '@local-storage/index';
import { merge } from 'lodash';

class UserLocalStorage {
  get(): IUserLocalStorage {
    const user = this.decrypt();
    return user ? JSON.parse(user) : user;
  }

  async set(user, subscription) {
    LocalStorage.set(KeysLocalStorageEnum.USER, null);
    const userData = this.adapter(user, subscription);
    const encrypted = this.encrypt(userData);
    localStorage.setItem(KeysLocalStorageEnum.USER, encrypted);
  }

  async merge(newStorage) {
    const oldStorage = this.get();
    const mergeStorage = merge(oldStorage, newStorage);
    const encrypted = this.encrypt(mergeStorage);
    localStorage.setItem(KeysLocalStorageEnum.USER, encrypted);
  }

  async setName(name) {
    LocalStorage.set(KeysLocalStorageEnum.NAME_USER, name);
  }

  decrypt() {
    const user = localStorage.getItem(KeysLocalStorageEnum.USER);
    if (!user) return null;
    return atob(String(user));
  }

  encrypt(data: {}) {
    const dataParseToString = JSON.stringify(data);
    return btoa(dataParseToString);
  }

  adapter(user, subscription): IUserLocalStorage {
    return {
      id: user.id,
      profile: user.profileId,
      email: user.email,
      typeId: user.userTypeId,
      subscriptionId: subscription?.subscriptionId || null,
      medicalFormationId: user.medicalFormationId,
      occupationAreaId: user.areaExpertiseId,
      specialtyId: user.specialtyId,
      formationDegree: user.degreeFormationType || user.formationDegree,
      graduationYear: parseInt(user.graduation, 10) || null,
      occupationArea: user.areaExpertise,
      registerDate: user.registerDate,
      specialty: user.specialty,
      uf: user.uf,
    };
  }
}

export default new UserLocalStorage();
